<template>
  <div style="width: 100%;height: 100%;">
    <div style="position: fixed;width: 100%;height: 100%;z-index: -500" :style="{background:`url('${bg}')`}"
         v-if="!isComp">
    </div>
    <div style="display: flex;flex-wrap: wrap;justify-content: center;align-items: center;height: 100%">
      <div style="width: 100%;">
        <inhead v-if="!isComp"></inhead>
      </div>

      <div style="width: 100%;height: 100%;">

        <div>
          <div style="font-weight: 600;font-size: 25px;text-align: center;margin: 7% 0 3% 0 ">
            <img :src="banTit" alt="">
          </div>


          <a-row justify="center" type="flex">
            <a-col :md="8" :sm="14">
              <a-input-search size="large" addon-before="搜索:" v-model="value"
                              placeholder="输入Steam17位ID/STEAM账号"
                              enter-button @search="onSearch"/>

            </a-col>
            <a-col :md="1" :sm="2">
            </a-col>
            <a-col :md="2" :sm="4">
              <a-button size="large" style="background: green" type="dashed" dashed icon="plus" @click="submitBlackList">提交黑名单
              </a-button>


            </a-col>
          </a-row>

        </div>

        <div>

          <a-row justify="center" type="flex">


            <a-col :md="10" :sm="20">


              <a-alert message="未找到该用户" show-icon type="success" v-if="isNull && this.blackList.length ==0"
                       style="margin-top: 5%;border-radius: 15px">
                <p slot="description" style="font-size: 16px;color: green;font-weight: 600;">
                  该用户信息尚未被录入! 但是我们不能保证交易绝对安全,提醒大家: 网络交易风险大,交易请谨慎!
                </p>
              </a-alert>


              <a-alert message="此为黑名单用户，谨慎交易" show-icon="" type="error" v-if="!isNull"
                       style="border-radius: 15px;margin-top: 5%;">
                <div slot="description">
                  <div style="width:100%;font-size: 16px;">STEAM账号:&nbsp;<b>{{ blackList.steam_account }}</b></div>

                  <div style="width: 100%;font-size: 16px;">STEAMID:&nbsp;<b>{{ blackList.steamid }}</b></div>
                  <div style="width:100%;font-size: 16px">电话号码:&nbsp;<b>{{ blackList.phone }}</b></div>
                  <div style="width: 100%;font-size: 16px">危险等级:&nbsp;<b>{{ blackList.level }}</b></div>
                  <div style="width: 100%;font-size: 16px">入黑时间:&nbsp;<b>{{ blackList.create_time }}</b></div>
                  <div style="width: 100%;font-size: 16px">拉黑原因:&nbsp;<b>{{ blackList.reason }}</b></div>
                  <div
                      style="width: 100%;font-size: 16px;letter-spacing: 3px;font-weight: 600;font-family: 黑体,sans-serif;margin-top: 3px;color: red">
                    PUBG号商群收集整理！ 谨慎交易！ 不要被骗!
                  </div>
                </div>
              </a-alert>

              <!--              <a-tag v-if="!isNull"-->
              <!--                     style="width: 100%;height: 180px;border-radius: 15px;margin-top: 5%;display: flex;flex-wrap: wrap;justify-content: center;padding: 15px"-->
              <!--                     :color="range[blackList.level]"-->
              <!--                     :style="{backgroundColor:rgb_range[blackList.level],boxShadow: `1px 2px 15px 2px ${rgb_range[blackList.level]}`}">-->
              <!--                <div style="width: 100%;font-size: 18px">STEAM账号:-->
              <!--                  <b>{{ blackList.steam_account }}</b></div>-->
              <!--                <div style="width: 100%;font-size: 18px">STEAMID: <b>{{ blackList.steamid }}</b></div>-->
              <!--                <div style="width:100%;font-size: 18px">电话号码:<b>{{ blackList.phone }}</b></div>-->
              <!--                <div style="width: 100%;font-size: 18px">危险等级: <b>{{ blackList.level }}</b></div>-->
              <!--                <a-tag :color="range[blackList.level]"-->
              <!--                       style="height: 30px;line-height: 30px;font-size: 16px;border-radius: 15px"-->
              <!--                       :style="{backgroundColor:rgb_range[blackList.level],boxShadow: `1px 2px 15px 2px ${rgb_range[blackList.level]}`}">-->
              <!--                  2222222222天天找回你🐎呢 你🐎什么时候死-->
              <!--                </a-tag>-->
              <!--              </a-tag>-->
            </a-col>
          </a-row>

        </div>


        <div>
          <a-row justify="center" type="flex">
            <a-col :md="12" :sm="22">

              <a-tag
                  style="width: 100%;height: 200px;border-radius: 15px;margin-top: 5%;padding: 15px" color="orange">
                <div style="font-size: 25px">
                  <a-icon type="smile"/> &nbsp;公告栏 <br>
                  <hr style="margin-top: 5px">
                </div>
                <div style="color: rgba(0,0,0,0.7);font-weight: bold;width: 100%;"
                     v-html="website_info.website_info[0].blacklist_notice">
                  <!--                <div style="font-size: 22px;">-->
                  <!--                  <p style="margin-top: 15px;">每天收集找回账号,欢迎小伙伴来举报! 举报需要提供有效证据-->
                  <!--                  </p>-->
                  <!--                  <div style="margin-top: 10px">网络鱼龙混杂交易谨慎避免金钱损失</div>-->
                  <!--                  <div style="margin-top: 10px">被他人恶意举报，账号恶意收录，请联系管理解除</div>-->
                  <!--                </div>-->
                </div>

              </a-tag>
            </a-col>
          </a-row>
        </div>


      </div>


      <div style="width: 100%">
        <infoot v-if="!isComp"></infoot>
      </div>

    </div>
  </div>

</template>

<script>
import inhead from "@/components/inhead";
import infoot from "@/components/infoot";
import {mapState} from "vuex";

const key = '123'
export default {
  components: {
    inhead, infoot
  },
  props: ['isComp'],
  name: "blackList",
  data() {
    return {
      bg: require('@/assets/bg_innerImg.jpg'),
      banTit: require('@/assets/ban-tit.png'),
      value: '',
      range: ['green', 'orange', 'purple', 'red'],
      rgb_range: ['rgba(97,150,83,0.1)', 'rgba(238,150,66,0.1)', 'rgba(128,109,153,0.1)', 'rgba(167,42,46,0.1)'],
      blackList: {},
      isNull: true,
    }
  },
  computed: {
    ...mapState('footer', ['website_info'])
  },
  methods: {
    submitBlackList(){
      const a = document.createElement('a')
      a.href='https://wj.qq.com/s2/14037881/f32d/'
      a.target='_blank'
      a.click()
    },
    onSearch() {
      this.$message.loading({content: '查询中', key, duration: 0})
      this.$axios.post('/Api/BlackList/SearchBlackList', {value: this.value}).then(value => {
        this.blackList = JSON.parse(value.data.data)
        this.isNull = false
        this.$message.success({content: value.data.msg, key, duration: 2})
      }).catch(err => {
        this.blackList = []
        this.isNull = true
        this.$message.warning({content: err.msg, key, duration: 2})
      })

    }
  },
  created() {
    const metas = document.getElementsByTagName("meta");
    metas[4].content = 'width=device-width,initial-scale=0.55'
  }

}
</script>

<style scoped>


</style>